//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Menu",
  data() {
    return {
      rutasMenu: [
        {titulo: "Venta", icono: "mdi-cart", menuLateral: [], to: "ventaMostrador"},
        {titulo: "Caja", icono: `img:${require('../../assets/icons/caja.svg')}`, menuLateral: [], to: "cajaMostrador"},
        {titulo: "Salida de lotes y series", icono: "archive", menuLateral: [], to: "salidaLotesSeries"},
        {titulo: "Corte", icono: `img:${require('../../assets/icons/cash-lock.svg')}`, menuLateral: [], to: "corteCaja"},
      ]
    }
  },
  methods: {
    validarActiveRuta(menuLateral) {
      let rutaValida = false;
      menuLateral.forEach(ml => {
        ml.opciones.forEach(r => {
          if (this.$route.name === r.ruta || _.filter(r.rutasRelacionadas, (rr) => this.$route.name === rr).length > 0)
            rutaValida = true;
        });
      });
      return rutaValida;
    },
    validarRutasHijos(opcion) {
      return (opcion.ruta !== "" &&
        (this.$route.name === opcion.ruta ||
          _.filter(opcion.rutasRelacionadas, (rr) => this.$route.name === rr).length > 0)
      );
    },
  }
}
